<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container v-if="!loading && stays">
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="elevation-x pb-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h5 font-alt">Patient</v-list-item-title>
              <v-list-item-subtitle class="font-alt font-weight-light text-caption">
                Synthèse des admissions
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-avatar size="54">
              <v-img src="@/assets/img/user-generic.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-light">
                Mon compte
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption font-alt mb-1">
                {{ $auth.user.username }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <template v-if="pendingStaySurveys.length">
            <v-list-item>
              <v-list-item-avatar size="54">
                <v-avatar size="30" class="font-weight-bold text-h6 white--text">
                  <font-awesome-icon :icon="['fad', 'exclamation-triangle']" size="1x" class="orange--text" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 font-weight-light">
                  {{ 'Questionnaire'|pluralize(pendingStaySurveys.length) }}
                  {{ 'disponible'|pluralize(pendingStaySurveys.length) }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption font-alt mb-1  white-space-inherit">
                  <template v-if="pendingStaySurveys.length===1">
                    Un questionnaire est disponible pour une de vos admission
                  </template>
                  <template v-else>
                    Des questionnaires sont disponibles pour certaines de vos admissions
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </template>
          <v-list-item>
            <v-list-item-avatar size="54">
              <v-avatar :color="admissionPendingCount > 0 ? 'orange' : 'grey'" size="30"
                        class="font-weight-bold text-h6 white--text"
              >
                {{ admissionPendingCount }}
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-1 font-weight-light">
                {{ 'Admission'|pluralize(admissionPendingCount) }} en cours
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption font-alt mb-1">
                {{ admissionPendingCount }} {{ 'admission'|pluralize(admissionPendingCount) }} en cours
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="elevation-x">
          <v-card-title class="pb-3 font-alt">Vos admissions</v-card-title>
          <v-card-subtitle class="font-weight-light text-caption font-alt">
            Liste de vos admissions en cours et passées
          </v-card-subtitle>
          <template v-for="(stay, index) in stays">
            <stay-list-item :key="stay.id" :stay="stay" />
            <v-divider v-if="index < stays.length -1" :key="stay.id+'-divider'" />
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <loader v-else />
</template>

<script>
  import Loader from '@/modules/core/components/Loader'
  import * as stays from '@/modules/stays/api'
  import StayListItem from '@/modules/patients/components/StayListItem'
  import { column } from '@/utils/array'

  export default {
    name: 'PatientHomePage',
    components: {StayListItem, Loader},
    data() {
      return {
        loading: true,
        stays: null,
        itemsPerPage: 150
      }
    },
    computed: {
      admissionPendingCount() {
        return this.stays.filter(stay => stay.status === 'in_progress' && this.$dayjs() < this.$dayjs(stay.startDate)).length
      },
      pendingStaySurveys() {
        let today = this.$dayjs()
        return column(this.stays.filter(stay => {
          return stay.staySurvey && !['acknowledged', 'complete', 'cancelled'].includes(stay.staySurvey.surveyResponseStatus)
            && this.$dayjs(stay.staySurvey.startDate) < today && this.$dayjs(stay.staySurvey.endDate) > today
        }), 'staySurvey')
      }
    },
    async created() {
      try {
        this.stays = (await stays.list({
          params: {
            itemsPerPage: this.itemsPerPage
          }
        })).data['hydra:member'].sort((a,b) => {
          if (a.startDate < b.startDate) {
            return 1
          }
          if (a.startDate > b.startDate) {
            return -1
          }
          return 0
        })
      } catch (error) {
        this.$root.$emit('error', error)
      } finally {
        this.loading = false
      }
    }
  }
</script>
