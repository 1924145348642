<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div>
    <v-list-item v-if="stayIsCancelled">
      <v-list-item-content>
        <v-list-item-title class="font-weight-light">
          Admission de
          <span class="font-weight-bold font-alt o-80">
            {{ stay.patientIdentity.firstName }} {{ stay.patientIdentity.lastName }}
          </span>
          <v-chip x-small color="grey lighten-2" text-color="black" dark class="ml-2">
            Admission annulée
          </v-chip>
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption font-alt">
          le
          <span class="font-weight-bold font-alt grey--text">
            {{ $dayjs(stay.startDate).format('LL') }}
          </span>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-caption font-alt">
          {{ stay.service.establishment.label }},
          {{ stay.service.label }} ({{ labelStayType(stay.type) }})
          {{ stay.doctor ? 'avec ' + stay.doctor.firstname + ' ' + stay.doctor.lastname : '' }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <template v-else>
      <v-list-item :key="stay.id" @click.stop="showForm(stay.id)">
        <v-list-item-content>
          <v-list-item-title class="font-weight-light">
            Admission de
            <span class="font-weight-bold font-alt o-80">
              {{ stay.patientIdentity.firstName }} {{ stay.patientIdentity.lastName }}
            </span>
            <v-chip v-if="$dayjs() > $dayjs(stay.startDate)" x-small class="ml-2">Admission archivée</v-chip>
            <v-chip v-else x-small :color="stay.status === 'validated' ? 'success' : 'orange'" dark class="ml-2">
              {{ stay.status === 'validated' ? 'Admission validée' : 'Admission en cours' }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt">
            le
            <span v-if="$dayjs() < $dayjs(stay.startDate)" class="font-weight-bold font-alt"
                  :class="stay.status === 'validated' ? 'success--text' : 'orange--text'"
            >
              {{ $dayjs(stay.startDate).format('LL') }}
            </span>
            <span v-else class="font-weight-bold font-alt grey--text">
              {{ $dayjs(stay.startDate).format('LL') }}
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-caption font-alt">
            {{ stay.service.establishment.label }},
            {{ stay.service.label }} ({{ labelStayType(stay.type) }})
            {{ stay.doctor ? 'avec ' + stay.doctor.firstname + ' ' + stay.doctor.lastname : '' }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="stay.status === 'in_progress' && $dayjs() < $dayjs(stay.startDate)"
                            class="progress-bar-info-small"
        >
          <v-progress-circular :rotate="270" :size="35" :width="4" :value="stay.completionRate"
                               :color="completionToColor(stay.completionRate)"
          >
            {{ stay.completionRate }}%
          </v-progress-circular>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="stay.staySurvey" :class="$vuetify.theme.dark ? '' : 'grey lighten-5'"
                   :disabled="!(waitingResponse || acquitSurvey || completeSurvey)"
                   @click.stop="linkToSurvey"
      >
        <v-list-item-avatar size="30">
          <font-awesome-icon v-if="notAvailable" :icon="['fad', 'calendar-day']" size="1x" class="grey--text" />
          <font-awesome-icon v-else-if="waitingResponse" :icon="['fad', 'pencil']" size="1x" class="orange--text" />
          <font-awesome-icon v-else-if="acquitSurvey" :icon="['fad', 'check-double']" size="1x" class="grey--text" />
          <font-awesome-icon v-else-if="completeSurvey" :icon="['fad', 'check-double']" size="1x" class="grey--text" />
          <font-awesome-icon v-else-if="expiredSurvey" :icon="['fad', 'times-circle']" size="1x" class="grey--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Questionnaire de suivi post-opératoire
          </v-list-item-title>

          <v-list-item-subtitle class="text-caption font-alt">
            <div v-if="notAvailable"> Vous pourrez remplir votre questionnaire le {{ $dayjs(stay.staySurvey.startDate).format('LLL') }}</div>
            <div v-else-if="waitingResponse">Vous pouvez remplir votre questionnaire</div>
            <div v-else-if="acquitSurvey">Votre questionnaire de suivi post-opératoire a été validé par un professionnel</div>
            <div v-else-if="completeSurvey">Vous avez bien envoyé votre questionnaire</div>
            <div v-else-if="expiredSurvey">Votre questionnaire de suivi post-opératoire est expiré</div>
          </v-list-item-subtitle>
        </v-list-item-content>
        <template v-if="waitingResponse">
          <v-list-item-action class="progress-bar-info-small">
            <v-chip small color="orange lighten-1"
                    class="font-alt" dark
            >
              <font-awesome-icon :icon="['fad', 'exclamation-triangle']" size="1x" class="mr-2" />
              En attente de réponse
            </v-chip>
          </v-list-item-action>
        </template>
        <template v-if="completeSurvey && !acquitSurvey">
          <v-list-item-action class="progress-bar-info-small">
            <v-chip small color="success lighten-1"
                    class="font-alt" dark
            >
              Questionnaire envoyé
            </v-chip>
          </v-list-item-action>
        </template>
        <template v-if="acquitSurvey">
          <v-list-item-action class="progress-bar-info-small">
            <v-chip small color="success"
                    class="font-alt" dark
            >
              Questionnaire validé
            </v-chip>
          </v-list-item-action>
        </template>
      </v-list-item>
      <survey-details-dialog v-if="showDetailDialog"
                             :survey-comment="stay.staySurvey.surveyResponseAcknowledgmentComment"
                             :survey-response="surveyResponse"
                             :survey-status="stay.staySurvey.surveyResponseStatus"
                             :patient-selected="stay.patientIdentity"
                             @close="showDetailDialog = false"
      />
    </template>
  </div>
</template>

<script>
  import * as surveys from '@/modules/surveys/api'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import SurveyDetailsDialog from '@/modules/surveys/components/responses/SurveyDetailsDialog'

  export default {
    name: 'StayListItem',
    components: {SurveyDetailsDialog},
    mixins: [dataDisplayers],
    props: {
      stay: Object
    },
    data() {
      return {
        showDetailDialog: false,
        surveyResponse: null,
        today: this.$dayjs.utc()
      }
    },
    computed: {
      stayIsCancelled () {
        return this.stay.status === 'cancelled'
      },
      notAvailable () {
        return this.$dayjs(this.stay.staySurvey.startDate) > this.today
      },
      waitingResponse () {
        return !this.notAvailable && !['acknowledged', 'complete'].includes(this.stay.staySurvey.surveyResponseStatus) && this.$dayjs(this.stay.staySurvey.endDate) > this.today
      },
      completeSurvey () {
        return this.stay.staySurvey.surveyResponseStatus === 'complete'
      },
      acquitSurvey () {
        return this.stay.staySurvey.surveyResponseStatus === 'acknowledged'
      },
      expiredSurvey () {
        return this.$dayjs(this.stay.staySurvey.endDate) < this.today
      }
    },
    methods: {
      showForm(stayId) {
        this.$router.push({name: 'admission-form', params: {stayId, comeFromList: true}})
      },
      async linkToSurvey() {
        if (this.completeSurvey || this.acquitSurvey) {
          try {
            this.surveyResponse = (await surveys.surveyResponses.get(this.stay.staySurvey.surveyResponseId)).data
          } catch (error) {
            this.$root.$emit('error', error)
          }
          this.showDetailDialog = true
        } else if (this.waitingResponse) {
          await this.$router.push({name: 'survey-form', params: {id: this.stay.staySurvey.id, item: this.stay.staySurvey}})
        }
      }
    }
  }
</script>

